import { useIsMobile } from "@obvio/app";
import Lenis from "@studio-freight/lenis";
import { useEffect, useRef } from "react";

import type { ReactElement } from "react";

type LenisScrollProps = {
  children: ReactElement;
};
export function LenisScroller({ children }: LenisScrollProps): ReactElement {
  const lenisRef = useRef<Lenis | null>(null);
  const isMobile = useIsMobile();

  useEffect(() => {
    if (isMobile) {
      lenisRef.current?.destroy();
      return;
    }

    lenisRef.current = new Lenis({
      duration: 1,
      smoothTouch: true,
    });

    function raf(time: number) {
      lenisRef.current?.raf(time);
      requestAnimationFrame(raf);
    }

    requestAnimationFrame(raf);

    return () => {
      lenisRef.current?.destroy();
    };
  }, [isMobile]);

  return children;
}
