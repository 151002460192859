import 'keen-slider/keen-slider.min.css'
import '../styles/globals.css'
import { css, Global, getHomeUrl } from '@obvio/app'
import { app } from '@obvio/app/App/index'

import { LenisScroller } from '@/components/LenisProvider'
import { dmSans, roslindale } from '@/constants/fonts'

export default app({
  contextPaths: {
    articles: '/blog',
  },
  seoData: {
    title: 'Szklarnia Mała Wieś',
    canonical: getHomeUrl(),
    description:
      'Szklarnia to przestrzeń w stylu rustykalnym – piękna bryła budynku, drewno i szkło oraz wyjście bezpośrednio na taras i polanę z miejscem na ognisko.',
    openGraph: {
      type: 'website',
      title: 'Szklarnia Mała Wieś',
      description:
        'Szklarnia to przestrzeń w stylu rustykalnym – piękna bryła budynku, drewno i szkło oraz wyjście bezpośrednio na taras i polanę z miejscem na ognisko.',
      images: [
        {
          url: `https://www.szklarniamalawies.pl/static/images/szklarnia.webp`,
          width: 1520,
          height: 1013,
          type: `image/webp`,
          alt: 'Szklarnia',
        },
      ],
    },
  },

  enhance: (C) => (p) =>
    (
      <>
        <LenisScroller>
          <C {...p} />
        </LenisScroller>

        <Global
          styles={css`
            :root {
              --font-family-dmSans: ${dmSans.style.fontFamily};
              --font-family-roslindale: ${roslindale.style.fontFamily};
            }
          `}
        />
      </>
    ),
})
